import { CACHE } from '@/cache'
import { CURRENT_CLIENT, LOGGED_IN } from '@/graphql'
import { checkRole, hasLoggedIn } from '@/utils'
import { APOLLO } from '@/vue-apollo'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
//#region Routes
const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'admin.dashboard' },
  },
  {
    path: '/admin',
    name: 'admin',
    component: () =>
      import(/* webpackChunkName: "AdminLayout" */ '../layouts/Admin.vue'),
    children: [
      {
        path: 'bull',
        name: 'admin.bull',
        component: () =>
          import(/* webpackChunkName: "Bull" */ '../views/admin/Bull'),
      },
      {
        path: 'company-notifications',
        name: 'admin.company_notifications',
        component: () =>
          import(
            /* webpackChunkName: "Bull" */ '../views/admin/CompanyNotifications'
          ),
      },
      {
        path: 'dashboard',
        name: 'admin.dashboard',
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ '../views/admin/Dashboard'
          ),
      },
      {
        path: 'errors',
        name: 'admin.errors',
        component: () =>
          import(/* webpackChunkName: "Dashboard" */ '../views/admin/Errors'),
      },
      {
        path: 'nodes',
        name: 'admin.nodes',
        component: () =>
          import(
            /* webpackChunkName: "Nodes" */ '../views/admin/nodes/NodesHome'
          ),
        redirect: { name: 'admin.nodes.list' },
        children: [
          {
            path: 'list',
            name: 'admin.nodes.list',
            component: () =>
              import(
                /* webpackChunkName: "Nodes" */ '../views/admin/nodes/Nodes'
              ),
            meta: {
              role: 'admin.nodes.list',
            },
          },
          {
            path: 'tree',
            name: 'admin.nodes.tree',
            component: () =>
              import(
                /* webpackChunkName: "Nodes" */ '../views/admin/nodes/NodesTree'
              ),
            meta: {
              role: 'admin.nodes.list',
            },
          },
          {
            path: ':node_id',
            name: 'admin.nodes.list.node_id',
            component: () =>
              import(
                /* webpackChunkName: "Nodes" */ '../views/admin/nodes/Node'
              ),
            meta: {
              role: 'admin.nodes.list',
            },
            redirect: { name: 'admin.nodes.list.node_id.base' },
            children: [
              {
                path: 'base',
                name: 'admin.nodes.list.node_id.base',
                component: () =>
                  import(
                    /* webpackChunkName: "Nodes" */ '../views/admin/nodes/NodeBase'
                  ),
                meta: {
                  role: 'admin.nodes.list',
                  nav: 'node',
                },
              },
              {
                path: 'clients',
                name: 'admin.nodes.list.node_id.clients',
                component: () =>
                  import(
                    /* webpackChunkName: "Nodes" */ '../views/admin/nodes/NodeClients'
                  ),
                meta: {
                  role: 'admin.nodes.list.clients',
                  nav: 'node',
                },
              },
              {
                path: 'errors',
                name: 'admin.nodes.list.node_id.errors',
                component: () =>
                  import(
                    /* webpackChunkName: "Nodes" */ '../views/admin/nodes/NodeErrors'
                  ),
                meta: {
                  role: 'admin.nodes.list.errors',
                  nav: 'node',
                },
              },
              {
                path: 'raw',
                name: 'admin.nodes.list.node_id.raw',
                component: () =>
                  import(
                    /* webpackChunkName: "Nodes" */ '../views/admin/nodes/NodeRaw'
                  ),
                meta: {
                  role: 'admin.nodes.list.raw',
                  nav: 'node',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'prospects',
        name: 'admin.prospects',
        component: () =>
          import(
            /* webpackChunkName: "Prospects" */ '../views/admin/prospects/Prospects'
          ),
      },
    ],
  },
  {
    path: '/auth',
    name: 'auth',
    meta: {
      public: true,
    },
    component: () => import(/* webpackChunkName: "Auth" */ '../layouts/Auth'),
    children: [
      {
        path: 'login',
        name: 'auth.login',
        meta: {
          public: true,
        },
        component: () =>
          import(/* webpackChunkName: "Auth" */ '../views/Login'),
      },
    ],
  },
]
//#endregion

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

//#region Guards & Hooks
router.beforeEach(async (to, from, next) => {
  //bypass all public routes and ones without roles
  if (to.meta?.public) {
    next()
  } else {
    // try tp get old session back on page reload
    console.log('currentClient', to, from.name)
    if (!from.name) {
      try {
        const result = await APOLLO.defaultClient.query({
          query: CURRENT_CLIENT,
        })
        const {
          data: { currentClient },
        } = result
        if (currentClient && currentClient?._id) {
          await hasLoggedIn(currentClient)
        } else {
          next({
            name: 'auth.login',
            query: { redirect: to.fullPath },
          })
        }
      } catch (error) {
        if (error.graphQLErrors) {
          error.graphQLErrors.forEach((err) => {
            if (err.extensions?.code === 'NOT_AUTHENTICATED') {
              next({
                name: 'auth.login',
                query: { redirect: to.fullPath },
              })
            }
          })
        }
      }
    }
    let { loggedIn } = CACHE.readQuery({ query: LOGGED_IN })
    if (!to.meta?.role && loggedIn) next()
    if (to.meta?.role && loggedIn) {
      let role = to.meta?.role
      checkRole(role) ? next() : next({ name: 'home' })
    }
  }
})
//#endregion

export default router
